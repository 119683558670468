import axios from 'axios';

const api = axios.create({
  //baseURL: 'https://www.itfgestor.com.br/ITFWebServiceSiteChiquinho', - Atual
  baseURL: 'https://rust.apte.com.br/ITFWebServiceSiteChiquinho', //Novo - Dezembro/2023
  // baseURL: 'http://localhost:8080/ITFWebServiceSiteChiquinho',
  // baseURL: 'http://192.168.11.28:8080/ITFWebServiceSiteChiquinho',
  // baseURL: 'http://e7cc602e6bb6.ngrok.io/ITFWebServiceSiteChiquinho',
});

export default api;
