import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';

import './styles.scss';

import logoChq from '../../../assets/img/logo-chq-250-250.png';

const HeaderMobile = () => {
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);

  function handleClickMenu(menu) {
    const pageHeight = window.innerHeight;
    const menuId = menu.id;

    if (menuId && menuId === 'onde-tem') {
      window.scrollTo({ top: pageHeight + 30, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <>
      <header>
        <div className="p-d-flex p-jc-center" style={{ width: '100%' }}>
          <div className="p-col-10">
            <div className="p-d-flex p-jc-between">
              <Link to="/">
                <img
                  src={logoChq}
                  alt="Logo Chiquinho"
                  width={90}
                  className="logo-chiquinho"
                />
              </Link>

              <Button
                icon="pi pi-bars"
                className="p-button-lg btn-menu-mobile"
                onClick={() => setVisibleFullScreen(true)}
              />
            </div>
          </div>
        </div>
      </header>

      <Sidebar
        visible={visibleFullScreen}
        fullScreen
        blockScroll
        baseZIndex={1000000}
        onHide={() => setVisibleFullScreen(false)}
        className="sidebar-mobile-menu"
      >
        <ScrollPanel style={{ width: '100%', height: '90vh' }}>
          <nav className="mobile-menu">
            <Link to="/" onClick={e => handleClickMenu(e.target)}>Home</Link>
            <Link to="/produtos" onClick={e => handleClickMenu(e.target)}>Produtos</Link>
            <Link id="onde-tem" to="/" onClick={e => handleClickMenu(e.target)}>Onde tem?</Link>
            <Link to="/seja-um-franqueado" onClick={e => handleClickMenu(e.target)}>Seja um franqueado</Link>
            <Link to="/sobre-a-marca" onClick={e => handleClickMenu(e.target)}>Sobre a marca</Link>
            <a href="https://ifoodbr.onelink.me/F4X4/chiquinhosorvetes" target='_blank'>Peça aqui</a>
          </nav>
        </ScrollPanel>
      </Sidebar>
    </>
  );
};

export default HeaderMobile;
