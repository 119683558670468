//rafce
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import './style.scss';

// import partePromo1 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-promo1.jpg';
// import partePromo2 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-promo2.jpg';
import parte1 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-01.jpg';
import parte2 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-02.jpg';
import parte3 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-03.jpg';
import parte4 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-04.jpg';
import parte5 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-05.jpg';
import parte6 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-06.jpg';
import parte7 from '../../assets/img/cardapio-full/vs2024-11/chiquinho-cardapio-online-07.jpg';

import api from '../../services/api';

const CardapioOnlineFull = () => {
  const { franquia } = useParams();
  const history = useHistory();

  const [loadingModal, setLoaddingModal] = useState(false);
  const [mostraModalCatchPrecos, setMostraModalCatchPrecos] = useState(false);

  const [products, setProducts] = useState([]);

  //Promo1 - destaque
  //const [pos100300177, setPos100300177] = useState({}); /*Milk-shake Chocotino com Morango Turbo - 300ml*/
  //const [pos100400111, setPos100400111] = useState({}); /*Milk-shake Chocotino com Morango Turbo - 400ml*/
  //const [pos100500111, setPos100500111] = useState({}); /*Milk-shake Chocotino com Morango Turbo - 500ml*/


  //const [pos100400110, setPos100400110] = useState({}); /*Milk-shake Chocotino Turbo - 400ml*/
  //const [pos100500110, setPos100500110] = useState({}); /*Milk-shake Chocotino Turbo - 500ml*/

  const [pos101300014, setPos101300014] = useState({}); /*Big Sundae Chocotino Turbo - 300ml - Chocolate, Baunilha e Misto */

  //Promo2 - destaque
  //const [pos100200137, setPos100200137] = useState({}); /*Canecake*/
  //const [pos100200142, setPos100200142] = useState({}); /*Adicional: Banana */
  //const [pos101900099, setPos101900099] = useState({}); /*Adicional: Morango */
  //const [pos100700127, setPos100700127] = useState({}); /*Adicional: Doce de Leite */
  //const [pos101900098, setPos101900098] = useState({}); /*Adicional: Calda de Fondue */
  //const [pos100700128, setPos100700128] = useState({}); /*Adicional: Nutella */
  //const [pos101900102, setPos101900102] = useState({}); /*Adicional: Creme de pistache*/
  //const [pos100700126, setPos100700126] = useState({}); /*Adicional: Farofa especial */


  //Chiquinho & Nutella - Cascão
  //Nutella e Banana
  const [pos100200137, setPos100200137] = useState({}); //Milk-shakes - fabulosos - 300ml
  const [pos100200138, setPos100200138] = useState({}); //Milk-shakes - fabulosos - 300ml
  const [pos100200139, setPos100200139] = useState({}); //Milk-shakes - fabulosos - 300ml
  //Nutella e Morango
  const [pos100200140, setPos100200140] = useState({}); //Milk-shakes - fabulosos - 400ml
  const [pos100200141, setPos100200141] = useState({}); //Milk-shakes - fabulosos - 400ml
  const [pos100200142, setPos100200142] = useState({}); //Milk-shakes - fabulosos - 400ml



  //milk-shakes - fabulosos
  // e
  //Chiquinho & Nutella - Milk Shake
  const [pos100300037, setPos100300037] = useState({}); //Milk-shakes - fabulosos - 300ml
  const [pos100400037, setPos100400037] = useState({}); //Milk-shakes - fabulosos - 400ml
  const [pos100500037, setPos100500037] = useState({}); //Milk-shakes - fabulosos - 500ml
  
  //milk-shakes - tradicionais
  const [pos100300003, setPos100300003] = useState({}); //Milk-shakes - tradicionais - 300ml
  const [pos100400003, setPos100400003] = useState({}); //Milk-shakes - tradicionais - 400ml
  const [pos100500003, setPos100500003] = useState({}); //Milk-shakes - tradicionais - 500ml

  //milk-shakes - receitas especiais
  const [pos100300171, setPos100300171] = useState({}); //Milk-shakes - pink lemonade - 300ml
  const [pos100300170, setPos100300170] = useState({}); //Milk-shakes - Napolitano - 300ml
  const [pos100300169, setPos100300169] = useState({}); //Milk-shakes - Três Leites - 300ml
  const [pos100300176, setPos100300176] = useState({}); //Milk-shake Chocotino Turbo - 300ml  

  //shake-mix
  const [pos100700025, setPos100700025] = useState({}); //insuperáveis - 300ml
  const [pos101900010, setPos101900010] = useState({}); //insuperáveis - mini - 200ml
  const [pos100700009, setPos100700009] = useState({}); //classicos - 300ml
  const [pos101900015, setPos101900015] = useState({}); //classicos - mini - 200ml
  
  //shake-mix - NUTELLA
  const [pos101900098, setPos101900098] = useState({}); //insuperáveis - 200ml
  const [pos100700128, setPos100700128] = useState({}); //insuperáveis - 300ml


  //shake-mix - receitas especiais
  const [pos101900029, setPos101900029] = useState({}); //shake-mix - Morango Supremo - 200ml
  const [pos101900027, setPos101900027] = useState({}); //shake-mix - Torta de Limão - 200ml
  const [pos101900028, setPos101900028] = useState({}); //shake-mix - Pistache - 200ml  
  const [pos101900026, setPos101900026] = useState({}); //shake-mix - Banoffee - 200ml  

  //top mix
  const [pos100600013, setPos100600013] = useState({}); //chocolate preferido
  const [pos100600034, setPos100600034] = useState({}); //com frutas

  //ovomaltine
  const [pos100800049, setPos100800049] = useState({}); //Sundae 200ml
  const [pos100200067, setPos100200067] = useState({}); //Cascão Trufado
  const [pos100100032, setPos100100032] = useState({}); //Casquinha Trufada

  const [pos101900019, setPos101900019] = useState({}); //Ovomaltine Mix 200ml
  const [pos100700017, setPos100700017] = useState({}); //Ovomaltine Mix 300ml

  //mix fruit
  const [pos101100003, setPos101100003] = useState({}); //200ml

  //cream tasty
  const [pos101000003, setPos101000003] = useState({}); //300ml

  //big sundae
  const [pos101300006, setPos101300006] = useState({}); //Big sundae - 300ml
  const [pos100800014, setPos100800014] = useState({}); //Sundae - 200ml

  //kids
  const [pos100800019, setPos100800019] = useState({}); //sundae kids - 200ml
  const [pos100700014, setPos100700014] = useState({}); //mini shake mix

  //casquinhas e cascões - tradicionais
  const [pos100100013, setPos100100013] = useState({}); //Tradicionais - Casquinha
  const [pos100200043, setPos100200043] = useState({}); //Tradicionais - Cascão

  //casquinhas e cascões - trufadas
  const [pos100100016, setPos100100016] = useState({}); //Trufadas - Casquinha
  const [pos100200053, setPos100200053] = useState({}); //Trufadas - Cascão

  //casquinhas e cascões - calda quente
  const [pos100100006, setPos100100006] = useState({}); //Calda quente - Casquinha coberta
  const [pos100100007, setPos100100007] = useState({}); //Calda quente - Casquinha recheada
  const [pos100200004, setPos100200004] = useState({}); //Calda quente - Cascão coberto
  const [pos100200007, setPos100200007] = useState({}); //Calda quente - Cascão recheado

  //petit gateau
  const [pos101500001, setPos101500001] = useState({});
  const [pos101500004, setPos101500004] = useState({});
  const [pos101500005, setPos101500005] = useState({});

  //bubble waffle
  const [pos290900020, setPos290900020] = useState({});

  //bubble waffle - adicionais
  const [pos290900001, setPos290900001] = useState({});
  const [pos290900002, setPos290900002] = useState({});
  const [pos290900003, setPos290900003] = useState({});
  const [pos290900004, setPos290900004] = useState({});
  const [pos290900005, setPos290900005] = useState({});
  const [pos290900006, setPos290900006] = useState({});
  const [pos290900019, setPos290900019] = useState({});
  const [pos290900007, setPos290900007] = useState({});
  const [pos290900008, setPos290900008] = useState({});
  const [pos290900009, setPos290900009] = useState({});
  const [pos290900010, setPos290900010] = useState({});
  const [pos290900011, setPos290900011] = useState({});
  const [pos290900012, setPos290900012] = useState({});
  const [pos290900013, setPos290900013] = useState({});
  const [pos290900014, setPos290900014] = useState({});
  const [pos290900015, setPos290900015] = useState({});
  const [pos290900016, setPos290900016] = useState({});
  const [pos290900017, setPos290900017] = useState({});
  const [pos290900018, setPos290900018] = useState({});

  // cafés e chocolates
  const [pos103000247, setPos103000247] = useState({}); /*Cafés e chocolates: Affogato 200ml (expresso com sorvete) */
  const [pos103000098, setPos103000098] = useState({});
  const [pos103000099, setPos103000099] = useState({});
  const [pos103000249, setPos103000249] = useState({});
  const [pos102000195, setPos102000195] = useState({}); /*Cafés e chocolates: Chocolate (quente ou frio) 200ml */
  const [pos102000197, setPos102000197] = useState({});
  
  //bebidas
  const [pos150100004, setPos150100004] = useState({}); // Água sem gás
  //const [pos100100002, setPos100100002] = useState({}); // Água com gás
  //const [pos000000001, setPos000000001] = useState({}); // Refrigerante
  //const [pos000000002, setPos000000002] = useState({}); // Suco

  //Café shake
  const [pos100300072, setPos100300072] = useState({}); // Nutella
  const [pos100300070, setPos100300070] = useState({}); // Tradicional
  const [pos100300071, setPos100300071] = useState({}); // Doce de Leite
  const [pos100300172, setPos100300172] = useState({}); // Doce de Leite
  const [pos100300173, setPos100300173] = useState({}); // Doce de Leite


  //açaí - monte o seu
  const [pos101400011, setPos101400011] = useState({}); // 300ml
  const [pos101400013, setPos101400013] = useState({}); // 400ml
  const [pos101400014, setPos101400014] = useState({}); // 500ml

  //açaí - turbine
  const [pos110000151, setPos110000151] = useState({});
  const [pos110000176, setPos110000176] = useState({});
  const [pos110000075, setPos110000075] = useState({});
  const [pos110000077, setPos110000077] = useState({});
  const [pos110000149, setPos110000149] = useState({});
  const [pos110000069, setPos110000069] = useState({});
  const [pos110000068, setPos110000068] = useState({});
  const [pos110000172, setPos110000172] = useState({});

  const [pos110000173, setPos110000173] = useState({});
  const [pos110000174, setPos110000174] = useState({});
  const [pos110000167, setPos110000167] = useState({});
  const [pos110000168, setPos110000168] = useState({});
  const [pos110000169, setPos110000169] = useState({});
  const [pos110000070, setPos110000070] = useState({});
  const [pos110000150, setPos110000150] = useState({});
  const [pos110000072, setPos110000072] = useState({});

  //add especiais
  const [pos110000076, setPos110000076] = useState({});
  const [pos110000071, setPos110000071] = useState({});
  const [pos110000073, setPos110000073] = useState({});
  const [pos110000152, setPos110000152] = useState({});
  const [pos110000153, setPos110000153] = useState({});

  const [pos110000175, setPos110000175] = useState({});
  const [pos110000171, setPos110000171] = useState({});
  const [pos110000074, setPos110000074] = useState({});
  const [pos110000170, setPos110000170] = useState({});

  //chiquinho no pote
  const [pos101700005, setPos101700005] = useState({}); // incomparáveis
  const [pos101700002, setPos101700002] = useState({}); // populares
  
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    async function getProducts() {
      setLoaddingModal(true);
      try {
        await api
          .get(
            `cardapio/${franquia}?key=2c87f686890c3edc89ae640aa360eb01`,
            options,
          )
          .then(res => {
            const productsMap = res.data.map(product => product);
            setProducts(productsMap);
          });

        setLoaddingModal(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setMostraModalCatchPrecos(true);
        }
      }

      setLoaddingModal(false);
    }

    getProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
		//Edição Setembro 2024 - Chocotino Modo Turbo
		//setPos100300177(products.find(prod => prod.codigo === "100300177")); /*Milk-shake Chocotino com Morango Turbo - 300ml*/
		//setPos100400111(products.find(prod => prod.codigo === "100400111")); /*Milk-shake Chocotino com Morango Turbo - 400ml*/
		//setPos100500111(products.find(prod => prod.codigo === "100500111")); /*Milk-shake Chocotino com Morango Turbo - 500ml*/

		setPos100300176(products.find(prod => prod.codigo === "100300176")); /*Milk-shake Chocotino Turbo - 300ml*/
		//setPos100400110(products.find(prod => prod.codigo === "100400110")); /*Milk-shake Chocotino Turbo - 400ml*/
		//setPos100500110(products.find(prod => prod.codigo === "100500110")); /*Milk-shake Chocotino Turbo - 500ml*/

		setPos101300014(products.find(prod => prod.codigo === "101300014")); /*Big Sundae Chocotino Turbo - 300ml - Chocolate, Baunilha e Misto */

		//Edição Julho 2024 - Nutella
		//setPos100200137(products.find(prod => prod.codigo === "100200137"));
		//setPos100200142(products.find(prod => prod.codigo === "100200142"));
		//setPos101900099(products.find(prod => prod.codigo === "101900099"));
		//setPos100700127(products.find(prod => prod.codigo === "100700127"));
		//setPos101900098(products.find(prod => prod.codigo === "101900098"));
		//setPos100700128(products.find(prod => prod.codigo === "100700128"));
		//setPos101900102(products.find(prod => prod.codigo === "101900102"));
		//setPos100700126(products.find(prod => prod.codigo === "100700126"));

    //Chiquinho & Nutella - Cascão
    //Nutella e Banana
    setPos100200137(products.find(prod => prod.codigo === "100200137"));
    setPos100200138(products.find(prod => prod.codigo === "100200138"));
    setPos100200139(products.find(prod => prod.codigo === "100200139"));
    //Nutella e Morango
    setPos100200140(products.find(prod => prod.codigo === "100200140"));
    setPos100200141(products.find(prod => prod.codigo === "100200141"));
    setPos100200142(products.find(prod => prod.codigo === "100200142"));

		//milk-shakes - fabulosos
		setPos100300037(products.find(prod => prod.codigo === "100300037"));
		setPos100400037(products.find(prod => prod.codigo === "100400037"));
		setPos100500037(products.find(prod => prod.codigo === "100500037"));

    //milk-shakes - tradicionais
		setPos100300003(products.find(prod => prod.codigo === "100300003"));
		setPos100400003(products.find(prod => prod.codigo === "100400003"));
		setPos100500003(products.find(prod => prod.codigo === "100500003"));

    //milk-shakes - receitas especiais
		setPos100300171(products.find(prod => prod.codigo === "100300171"));
		setPos100300170(products.find(prod => prod.codigo === "100300170"));
		setPos100300169(products.find(prod => prod.codigo === "100300169"));
		setPos100300176(products.find(prod => prod.codigo === "100300176"));

		//shake-mix - insuperaveis
		setPos100700025(products.find(prod => prod.codigo === "100700025"));
		setPos101900010(products.find(prod => prod.codigo === "101900010"));
    
    //shake-mix - classicos
		setPos100700009(products.find(prod => prod.codigo === "100700009"));
		setPos101900015(products.find(prod => prod.codigo === "101900015"));
    
		//shake-mix - nutella
		setPos101900098(products.find(prod => prod.codigo === "101900098"));
		setPos100700128(products.find(prod => prod.codigo === "100700128"));

		//top-mix
		setPos100600013(products.find(prod => prod.codigo === "100600013"));
		setPos100600034(products.find(prod => prod.codigo === "100600034"));

		//ovomaltine
		setPos100800049(products.find(prod => prod.codigo === "100800049"));
		setPos100200067(products.find(prod => prod.codigo === "100200067"));
		setPos100100032(products.find(prod => prod.codigo === "100100032"));
		setPos101900019(products.find(prod => prod.codigo === "101900019"));
		setPos100700017(products.find(prod => prod.codigo === "100700017"));

		//mix-fruit
		setPos101100003(products.find(prod => prod.codigo === "101100003"));

		//big-sundae
		setPos101300006(products.find(prod => prod.codigo === "101300006"));

		//sundae
		setPos100800014(products.find(prod => prod.codigo === "100800014"));

		//cream tasty
		setPos101000003(products.find(prod => prod.codigo === "101000003"));

		//kids
		setPos100800019(products.find(prod => prod.codigo === "100800019"));
		setPos100700014(products.find(prod => prod.codigo === "100700014"));

		//casquinhas e cascões
		//tradicionais
		setPos100100013(products.find(prod => prod.codigo === "100100013")); //
		setPos100200043(products.find(prod => prod.codigo === "100200043"));

		//trufados
		setPos100100016(products.find(prod => prod.codigo === "100100016"));
		setPos100200053(products.find(prod => prod.codigo === "100200053"));

		//calda quente
		setPos100100006(products.find(prod => prod.codigo === "100100006"));
		setPos100100007(products.find(prod => prod.codigo === "100100007"));
		setPos100200004(products.find(prod => prod.codigo === "100200004"));
		setPos100200007(products.find(prod => prod.codigo === "100200007"));

		//petit gateau
		setPos101500001(products.find(prod => prod.codigo === "101500001"));
		setPos101500004(products.find(prod => prod.codigo === "101500004"));
		setPos101500005(products.find(prod => prod.codigo === "101500005"));

		//mini shake mix
    // e
    //receitas especiais
		setPos101900029(products.find(prod => prod.codigo === "101900029"));
		setPos101900027(products.find(prod => prod.codigo === "101900027"));
		setPos101900028(products.find(prod => prod.codigo === "101900028"));
		setPos101900026(products.find(prod => prod.codigo === "101900026"));

		//bubble waffle no cone
		setPos290900020(products.find(prod => prod.codigo === "290900020"));

		//bubble waffle no cone: adicionais
		setPos290900001(products.find(prod => prod.codigo === "290900001"));
		setPos290900002(products.find(prod => prod.codigo === "290900002"));
		setPos290900003(products.find(prod => prod.codigo === "290900003"));
		setPos290900004(products.find(prod => prod.codigo === "290900004"));
		setPos290900005(products.find(prod => prod.codigo === "290900005"));
		setPos290900006(products.find(prod => prod.codigo === "290900006"));
		setPos290900019(products.find(prod => prod.codigo === "290900019"));
		setPos290900007(products.find(prod => prod.codigo === "290900007"));
		setPos290900008(products.find(prod => prod.codigo === "290900008"));
		setPos290900009(products.find(prod => prod.codigo === "290900009"));
		setPos290900010(products.find(prod => prod.codigo === "290900010"));
		setPos290900011(products.find(prod => prod.codigo === "290900011"));
		setPos290900012(products.find(prod => prod.codigo === "290900012"));
		setPos290900013(products.find(prod => prod.codigo === "290900013"));
		setPos290900014(products.find(prod => prod.codigo === "290900014"));
		setPos290900015(products.find(prod => prod.codigo === "290900015"));
		setPos290900016(products.find(prod => prod.codigo === "290900016"));
		setPos290900017(products.find(prod => prod.codigo === "290900017"));
		setPos290900018(products.find(prod => prod.codigo === "290900018"));

		//cafés e chocolates
		setPos103000247(products.find(prod => prod.codigo === "103000247"));
		setPos103000098(products.find(prod => prod.codigo === "103000098"));
		setPos103000099(products.find(prod => prod.codigo === "103000099"));
		setPos103000249(products.find(prod => prod.codigo === "103000249"));
		setPos102000195(products.find(prod => prod.codigo === "102000195"));
		setPos102000197(products.find(prod => prod.codigo === "102000197"));

		setPos150100004(products.find(prod => prod.codigo === "150100004"));
		//setPos100100002(products.find(prod => prod.codigo === "100100002"));
		//setPos000000001(products.find(prod => prod.codigo === "000000001"));
		//setPos000000002(products.find(prod => prod.codigo === "000000002"));

		//café shake: 300ml
		setPos100300072(products.find(prod => prod.codigo === "100300072"));
		setPos100300070(products.find(prod => prod.codigo === "100300070"));
		setPos100300071(products.find(prod => prod.codigo === "100300071"));

		setPos100300172(products.find(prod => prod.codigo === "100300172"));
		setPos100300173(products.find(prod => prod.codigo === "100300173"));

		//açaí
		setPos101400011(products.find(prod => prod.codigo === "101400011"));
		setPos101400013(products.find(prod => prod.codigo === "101400013"));
		setPos101400014(products.find(prod => prod.codigo === "101400014"));

		//açaí: turbine com adicionais
		setPos110000151(products.find(prod => prod.codigo === "110000151"));
		setPos110000176(products.find(prod => prod.codigo === "110000176"));
		setPos110000075(products.find(prod => prod.codigo === "110000075"));
		setPos110000077(products.find(prod => prod.codigo === "110000077"));
		setPos110000149(products.find(prod => prod.codigo === "110000149"));
		setPos110000069(products.find(prod => prod.codigo === "110000069"));
		setPos110000068(products.find(prod => prod.codigo === "110000068"));
		setPos110000172(products.find(prod => prod.codigo === "110000172"));

		//açaí: add especiais
		setPos110000173(products.find(prod => prod.codigo === "110000173"));
		setPos110000174(products.find(prod => prod.codigo === "110000174"));
		setPos110000167(products.find(prod => prod.codigo === "110000167"));
		setPos110000168(products.find(prod => prod.codigo === "110000168"));
		setPos110000169(products.find(prod => prod.codigo === "110000169"));
		setPos110000070(products.find(prod => prod.codigo === "110000070"));
		setPos110000150(products.find(prod => prod.codigo === "110000150"));
		setPos110000072(products.find(prod => prod.codigo === "110000072"));
		 
		setPos110000076(products.find(prod => prod.codigo === "110000076"));
		setPos110000071(products.find(prod => prod.codigo === "110000071"));
		setPos110000073(products.find(prod => prod.codigo === "110000073"));
		setPos110000152(products.find(prod => prod.codigo === "110000152"));
		setPos110000153(products.find(prod => prod.codigo === "110000153"));
		 
		setPos110000175(products.find(prod => prod.codigo === "110000175"));
		setPos110000171(products.find(prod => prod.codigo === "110000171"));
		setPos110000074(products.find(prod => prod.codigo === "110000074"));
		setPos110000170(products.find(prod => prod.codigo === "110000170"));

		//chiquinho no pote
		//incomparáveis
		setPos101700005(products.find(prod => prod.codigo === "101700005"));
		//populares
		setPos101700002(products.find(prod => prod.codigo === "101700002"));

    }
  }, [products]);

  function handleClickButtonModalCatchPrecos() {
    setMostraModalCatchPrecos(false);
    history.push('/');
  }

  function Indisponivel(props) {
    return <span className={String(props.tamanho)}>Indisponível</span>;
  }

  // const indisponivel_1vw = (
  //   <Indisponivel tamanho="font-size-1vw indisponivel" />
  // );

  const indisponivel_1_1vw = (
    <Indisponivel tamanho="font-size-1-1vw indisponivel" />
  );

  // const indisponivel_1_2vw = (
  //   <Indisponivel tamanho="font-size-1-2vw indisponivel" />
  // );

  // const indisponivel_1_3vw = (
  //   <Indisponivel tamanho="font-size-1-3vw indisponivel" />
  // );

  // const indisponivel_1_4vw = (
  //   <Indisponivel tamanho="font-size-1-4vw indisponivel" />
  // );

  // const indisponivel_1_5vw = (
  //   <Indisponivel tamanho="font-size-1-5vw indisponivel" />
  // );

  // const indisponivel_1_7vw = (
  //   <Indisponivel tamanho="font-size-1-7vw indisponivel" />
  // );

  // const indisponivel_1_9vw = (
  //   <Indisponivel tamanho="font-size-1-9vw indisponivel" />
  // );

  return (
    <>
      <section id="cardapio-online-full">

            {/* <section className="categoria partePromo1">

              <span className="val-1 valor">{pos100300177 && pos100300177.preco ? pos100300177.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-2 valor">{pos100400111 && pos100400111.preco ? pos100400111.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-3 valor">{pos100500111 && pos100500111.preco ? pos100500111.preco.toFixed(2) : indisponivel_1_1vw}</span>

              <span className="val-4 valor">{pos100300176 && pos100300176.preco ? pos100300176.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-5 valor">{pos100400110 && pos100400110.preco ? pos100400110.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-6 valor">{pos100500110 && pos100500110.preco ? pos100500110.preco.toFixed(2) : indisponivel_1_1vw}</span>

              <span className="val-7 valor">{pos101300014 && pos101300014.preco ? pos101300014.preco.toFixed(2) : indisponivel_1_1vw}</span>          

              <img src={partePromo1} alt="partePromo1" />
          

            </section> */}

            {/* <section className="categoria partePromo2">

              <span className="val-1 valor">{pos100200137 && pos100200137.preco ? pos100200137.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-2 valor">{pos100200142 && pos100200142.preco ? pos100200142.preco.toFixed(2) : indisponivel_1_1vw}</span>

              <span className="val-3 valor">{pos101900099 && pos101900099.preco ? pos101900099.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-4 valor">{pos100700127 && pos100700127.preco ? pos100700127.preco.toFixed(2) : indisponivel_1_1vw}</span>

              <span className="val-5 valor">{pos101900098 && pos101900098.preco ? pos101900098.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-6 valor">{pos100700128 && pos100700128.preco ? pos100700128.preco.toFixed(2) : indisponivel_1_1vw}</span>

              <span className="val-7 valor">{pos101900102 && pos101900102.preco ? pos101900102.preco.toFixed(2) : indisponivel_1_1vw}</span>
              <span className="val-8 valor">{pos100700126 && pos100700126.preco ? pos100700126.preco.toFixed(2) : indisponivel_1_1vw}</span>
          

              <img src={partePromo2} alt="partePromo2" />
          

            </section> */}

        <section className="categoria parte1">

          {/*Dobra 1*/}
          <span className="val-1 valor">{pos100300037 && pos100300037.preco ? pos100300037.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-2 valor">{pos100400037 && pos100400037.preco ? pos100400037.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos100500037 && pos100500037.preco ? pos100500037.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <span className="val-4 valor">{pos100300003 && pos100300003.preco ? pos100300003.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos100400003 && pos100400003.preco ? pos100400003.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-6 valor">{pos100500003 && pos100500003.preco ? pos100500003.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*receitas especiais*/}
          <span className="val-7 valor">{pos100300171 && pos100300171.preco ? pos100300171.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-8 valor">{pos100300170 && pos100300170.preco ? pos100300170.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-9 valor">{pos100300169 && pos100300169.preco ? pos100300169.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-10 valor">{pos100300176 && pos100300176.preco ? pos100300176.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 2*/}
          <span className="val-11 valor">{pos100700025 && pos100700025.preco ? pos100700025.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-12 valor">{pos101900010 && pos101900010.preco ? pos101900010.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-13 valor">{pos100700009 && pos100700009.preco ? pos100700009.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-14 valor">{pos101900015 && pos101900015.preco ? pos101900015.preco.toFixed(2) : indisponivel_1_1vw}</span>
          
          {/*receitas especiais*/}
          <span className="val-15 valor">{pos101900029 && pos101900029.preco ? pos101900029.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-16 valor">{pos101900027 && pos101900027.preco ? pos101900027.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-17 valor">{pos101900028 && pos101900028.preco ? pos101900028.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-18 valor">{pos101900026 && pos101900026.preco ? pos101900026.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 3*/}
          <span className="val-19 valor">{pos100600013 && pos100600013.preco ? pos100600013.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-20 valor">{pos100600034 && pos100600034.preco ? pos100600034.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte1} alt="parte1" />

        </section>

        <section className="categoria parte2">

          {/*Dobra 1*/}
          <span className="val-1 valor">{pos100800049 && pos100800049.preco ? pos100800049.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-2 valor">{pos100200067 && pos100200067.preco ? pos100200067.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos100100032 && pos100100032.preco ? pos100100032.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <span className="val-4 valor">{pos101900019 && pos101900019.preco ? pos101900019.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos100700017 && pos100700017.preco ? pos100700017.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 2*/}
          <span className="val-6 valor">{pos101100003 && pos101100003.preco ? pos101100003.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-7 valor">{pos101000003 && pos101000003.preco ? pos101000003.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 3 - Chiquinho & Nutella*/}
          {/*Cascão - Nutella e Banana & Nutella e Morango*/}
          <span className="val-8a-1 valor">{pos100200137 && pos100200137.preco ? pos100200137.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-8b-1 valor">{pos100200140 && pos100200140.preco ? pos100200140.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Shake Mix - 200ml*/}
          <span className="val-9 valor">{pos101900010 && pos101900010.preco ? pos101900010.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*Shake Mix - 300ml*/}
          <span className="val-10 valor">{pos100700025 && pos100700025.preco ? pos100700025.preco.toFixed(2) : indisponivel_1_1vw}</span>
          

          {/*milk shake - 300ml*/}
          <span className="val-11 valor">{pos100300037 && pos100300037.preco ? pos100300037.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*milk shake - 400ml*/}
          <span className="val-12 valor">{pos100400037 && pos100400037.preco ? pos100400037.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*milk shake - 500ml*/}
          <span className="val-13 valor">{pos100500037 && pos100500037.preco ? pos100500037.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 4*/}
          {/*Big sundae - Tradicional*/}
          <span className="val-14 valor">{pos101300006 && pos101300006.preco ? pos101300006.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*Big sundae - Chocotino Turbo*/}
          {/* <span className="val-15 valor">{pos100800014 && pos100800014.preco ? pos100800014.preco.toFixed(2) : indisponivel_1_1vw}</span> */}
          <span className="val-15 valor">{pos101300014 && pos101300014.preco ? pos101300014.preco.toFixed(2) : indisponivel_1_1vw}</span>
          
          {/*sundae - 200ml*/}
          <span className="val-16 valor">{pos100800014 && pos100800014.preco ? pos100800014.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 5 - Kids*/}
          {/*sundae Kids - 200ml*/}
          <span className="val-17 valor">{pos100800019 && pos100800019.preco ? pos100800019.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*mini shake mix*/}
          <span className="val-18 valor">{pos100700014 && pos100700014.preco ? pos100700014.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 6*/}
          {/*Casquinhas & Cascões*/}
          {/*Tradicionais*/}
          <span className="val-19 valor">{pos100100013 && pos100100013.preco ? pos100100013.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-20 valor">{pos100200043 && pos100200043.preco ? pos100200043.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Trufadas*/}
          <span className="val-21 valor">{pos100100016 && pos100100016.preco ? pos100100016.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-22 valor">{pos100200053 && pos100200053.preco ? pos100200053.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*calda quente*/}
          <span className="val-23 valor">{pos100100006 && pos100100006.preco ? pos100100006.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-24 valor">{pos100100007 && pos100100007.preco ? pos100100007.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-25 valor">{pos100200004 && pos100200004.preco ? pos100200004.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-26 valor">{pos100200007 && pos100200007.preco ? pos100200007.preco.toFixed(2) : indisponivel_1_1vw}</span>


          <img src={parte2} alt="parte2" />

        </section>

        <section className="categoria parte3">

          {/*Dobra 1*/}
          {/*Petit gateau*/}
          <span className="val-1 valor">{pos101500001 && pos101500001.preco ? pos101500001.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-2 valor">{pos101500004 && pos101500004.preco ? pos101500004.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos101500005 && pos101500005.preco ? pos101500005.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*Dobra 2*/}
          {/*receitas especiais - mini shake mix - 200ml*/}
          <span className="val-4 valor">{pos101900029 && pos101900029.preco ? pos101900029.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos101900027 && pos101900027.preco ? pos101900027.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-6 valor">{pos101900028 && pos101900028.preco ? pos101900028.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-7 valor">{pos101900026 && pos101900026.preco ? pos101900026.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte3} alt="parte3" />

        </section>    

        <section className="categoria parte4">

          {/*Dobra 1*/}
          {/*bubble waffle no cone*/}
          <span className="val-1 valor">{pos290900020 && pos290900020.preco ? pos290900020.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*adicionais*/}
          <span className="val-2 valor">{pos290900001 && pos290900001.preco ? pos290900001.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos290900002 && pos290900002.preco ? pos290900002.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-4 valor">{pos290900003 && pos290900003.preco ? pos290900003.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos290900004 && pos290900004.preco ? pos290900004.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-6 valor">{pos290900005 && pos290900005.preco ? pos290900005.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-7 valor">{pos290900006 && pos290900006.preco ? pos290900006.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-8 valor">{pos290900019 && pos290900019.preco ? pos290900019.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-9 valor">{pos290900007 && pos290900007.preco ? pos290900007.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-10 valor">{pos290900008 && pos290900008.preco ? pos290900008.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-11 valor">{pos290900009 && pos290900009.preco ? pos290900009.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-12 valor">{pos290900010 && pos290900010.preco ? pos290900010.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-13 valor">{pos290900011 && pos290900011.preco ? pos290900011.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-14 valor">{pos290900012 && pos290900012.preco ? pos290900012.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-15 valor">{pos290900013 && pos290900013.preco ? pos290900013.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-16 valor">{pos290900014 && pos290900014.preco ? pos290900014.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-17 valor">{pos290900015 && pos290900015.preco ? pos290900015.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-18 valor">{pos290900016 && pos290900016.preco ? pos290900016.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-19 valor">{pos290900017 && pos290900017.preco ? pos290900017.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-20 valor">{pos290900018 && pos290900018.preco ? pos290900018.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte4} alt="parte4" />

        </section>

        <section className="categoria parte5">

          {/*Dobra 1*/}
          {/*cafés e chocolates*/}
          <span className="val-1 valor">{pos103000247 && pos103000247.preco ? pos103000247.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-2 valor">{pos103000098 && pos103000098.preco ? pos103000098.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos103000099 && pos103000099.preco ? pos103000099.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-4 valor">{pos103000249 && pos103000249.preco ? pos103000249.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos102000195 && pos102000195.preco ? pos102000195.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-6 valor">{pos102000197 && pos102000197.preco ? pos102000197.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*bebidas*/}
          <span className="val-7 valor">{pos150100004 && pos150100004.preco ? pos150100004.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*<span className="val-8 valor">{pos100100002 && pos100100002.preco ? pos100100002.preco.toFixed(2) : indisponivel_1_1vw}</span> {/*Água com gás*/}
          {/*<span className="val-9 valor">{pos000000001 && pos000000001.preco ? pos000000001.preco.toFixed(2) : indisponivel_1_1vw}</span> {/*Refrigerante*/}
          {/*<span className="val-10 valor">{pos000000002 && pos000000002.preco ? pos000000002.preco.toFixed(2) : indisponivel_1_1vw}</span> {/*Suco*/}
          <span className="val-8 valor">Consultar no caixa</span> {/*Água com gás*/}
          <span className="val-9 valor">Consultar no caixa</span> {/*Refrigerante*/}
          <span className="val-10 valor">Consultar no caixa</span> {/*Suco*/}
          
          {/*Dobra 2*/}
          {/*cafés shake - 300ml*/}
          <span className="val-11 valor">{pos100300072 && pos100300072.preco ? pos100300072.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-12 valor">{pos100300070 && pos100300070.preco ? pos100300070.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-13 valor">{pos100300071 && pos100300071.preco ? pos100300071.preco.toFixed(2) : indisponivel_1_1vw}</span>
            
          {/*novos sabores - Trufado*/}
          <span className="val-14 valor">{pos100300172 && pos100300172.preco ? pos100300172.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*novos sabores - Pé de Moleque*/}
          <span className="val-15 valor">{pos100300173 && pos100300173.preco ? pos100300173.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte5} alt="parte5" />

        </section>

        <section className="categoria parte6">

          {/*Dobra 1*/}
          {/*Açaí*/}
          {/*monte o seu*/}
          <span className="val-1 valor">{pos101400011 && pos101400011.preco ? pos101400011.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-2 valor">{pos101400013 && pos101400013.preco ? pos101400013.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-3 valor">{pos101400014 && pos101400014.preco ? pos101400014.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*turbine com adicionais*/}
          <span className="val-4 valor">{pos110000151 && pos110000151.preco ? pos110000151.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-5 valor">{pos110000176 && pos110000176.preco ? pos110000176.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-6 valor">{pos110000075 && pos110000075.preco ? pos110000075.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-7 valor">{pos110000077 && pos110000077.preco ? pos110000077.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-8 valor">{pos110000149 && pos110000149.preco ? pos110000149.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-9 valor">{pos110000069 && pos110000069.preco ? pos110000069.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-10 valor">{pos110000068 && pos110000068.preco ? pos110000068.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-11 valor">{pos110000172 && pos110000172.preco ? pos110000172.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <span className="val-12 valor">{pos110000173 && pos110000173.preco ? pos110000173.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-13 valor">{pos110000174 && pos110000174.preco ? pos110000174.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-14 valor">{pos110000167 && pos110000167.preco ? pos110000167.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-15 valor">{pos110000168 && pos110000168.preco ? pos110000168.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-16 valor">{pos110000169 && pos110000169.preco ? pos110000169.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-17 valor">{pos110000070 && pos110000070.preco ? pos110000070.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-18 valor">{pos110000150 && pos110000150.preco ? pos110000150.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-19 valor">{pos110000072 && pos110000072.preco ? pos110000072.preco.toFixed(2) : indisponivel_1_1vw}</span>

          {/*add especiais*/}
          <span className="val-20 valor">{pos110000076 && pos110000076.preco ? pos110000076.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-21 valor">{pos110000071 && pos110000071.preco ? pos110000071.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-22 valor">{pos110000073 && pos110000073.preco ? pos110000073.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-23 valor">{pos110000152 && pos110000152.preco ? pos110000152.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-24 valor">{pos110000153 && pos110000153.preco ? pos110000153.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <span className="val-25 valor">{pos110000175 && pos110000175.preco ? pos110000175.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-26 valor">{pos110000171 && pos110000171.preco ? pos110000171.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-27 valor">{pos110000074 && pos110000074.preco ? pos110000074.preco.toFixed(2) : indisponivel_1_1vw}</span>
          <span className="val-28 valor">{pos110000170 && pos110000170.preco ? pos110000170.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte6} alt="parte6" />
        </section>

        <section className="categoria parte7">

          {/*Dobra 1*/}
          {/*chiquinho no pote*/}
          {/*incomparaveis*/}
          <span className="val-1 valor">{pos101700005 && pos101700005.preco ? pos101700005.preco.toFixed(2) : indisponivel_1_1vw}</span>
          {/*populares*/}
          <span className="val-2 valor">{pos101700002 && pos101700002.preco ? pos101700002.preco.toFixed(2) : indisponivel_1_1vw}</span>

          <img src={parte7} alt="parte7" />

        </section>

      </section>

      <Dialog
        className="dialog-loadding"
        header="Carregando Informações"
        visible={loadingModal}
        closable={false}
        onHide={() => setLoaddingModal(false)}
      >
        <main
          style={{
            padding: '1em',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
        </main>
      </Dialog>

      <Dialog
        header="Problemas para buscar preços"
        className="dialog-footer"
        visible={mostraModalCatchPrecos}
        onHide={() => setMostraModalCatchPrecos(false)}
        footer={
          <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => handleClickButtonModalCatchPrecos()}
            autoFocus
          />
        }
      >
        <p>
          Não Foi possível carregar os preçoes da franquia, tente novamente mais tarde!
        </p>
      </Dialog>
    </>
  );
};

export default CardapioOnlineFull;
