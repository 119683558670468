import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.css';
import imgCardapio from '../../assets/img/2024/08/chiquinho-cardapio-simplificado-site-08-2024.jpg';
import pdfTabelaNutricional from '../../assets/img/2024/11/chiquinho-tabela-nutricional-completa-11-2024.pdf';

import { Link } from 'react-router-dom';

const Produtos = () => {
  return (
    <>
      <Header />

      <section id="cardapio">
        <div id="divProdutos">
          <img src={imgCardapio} alt="Produtos" />
        </div>

        <div className="download-nutrition-table">
          Para mais informações, <Link to={pdfTabelaNutricional} target="_blank">clique aqui{' '}</Link> para consultar a tabela nutricional
          
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Produtos;
