import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const LojaShopping = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="loja-shopping">
        <div className="p-d-flex p-jc-center header-shopping">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">LOJA SHOPPING</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>PERFIL</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
                <li>Operação que oferece acessibilidade, segurança e conforto ao consumidor;</li>
                <li>Empreendimento com fluxo e concentração de pessoas;</li>
                <li>Menor oscilação de faturamento em razão do poder de consumo;</li>
                <li>Maior facilidade no alcance do público-alvo;</li>
                <li>Área Mínima 35 m².</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
              <li>Faturamento médio mensal: R$ 105 mil</li>
              <li>Lucro médio mensal entre 17% e 22%</li>
              <li>Prazo de retorno de 24 a 36 meses</li>
              <li>Investimento inicial R$ 370.000,00*</li>
              <li>Taxa de Franquia R$ 60.000,00</li>
              <li>Taxa de Royalties 5%</li>
              <li>Taxa de Publicidade 2%</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, impostos, frete etc. Valor do estoque está incluso no investimento inicial.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LojaShopping;
