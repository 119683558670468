import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// import Home from './pages/Home';
import HomeFull from './pages/HomeFull';
import SobreAMarca from './pages/SobreAMarca';
import SejaUmFranqueado from './pages/SejaUmFranqueado/indexPipefy';
import LojaTradicional from './pages/SejaUmFranqueado/Lojas/Tradicional';
import LojaShopping from './pages/SejaUmFranqueado/Lojas/Shopping';
import LojaBasica from './pages/SejaUmFranqueado/Lojas/Basica';
import Quiosque from './pages/SejaUmFranqueado/Lojas/Quiosque';
import ModelosExternos from './pages/SejaUmFranqueado/Lojas/ModelosExternos';
import Contato from './pages/Contato';

//import Cardapio from './pages/Cardapio';
//import ShakeMix from './pages/Cardapio/ShakeMix';
//import NovoMix from './pages/Cardapio/NovoMix';
//import MilkShake from './pages/Cardapio/MilkShake';
//import Ovomaltine from './pages/Cardapio/Ovomaltine';
//import BigSundae from './pages/Cardapio/BigSundae';
//import PetitGateau from './pages/Cardapio/PetitGateau';
//import CreamTasty from './pages/Cardapio/CreamTasty';
//import Pote from './pages/Cardapio/Pote';
//import Casquinha from './pages/Cardapio/Casquinha';
//import Acai from './pages/Cardapio/Acai';
//import MixFruit from './pages/Cardapio/MixFruit';
//import CafeShake from './pages/Cardapio/CafeShake';

import CardapioOnlineFull from './pages/cardapioOnlineFull-2024-11';

//import InformacoesNutricionais from './pages/Cardapio/InformacoesNutricionais';

import Produtos from './pages/Cardapio';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={HomeFull} path="/" exact />
        <Route component={SobreAMarca} path="/sobre-a-marca" />
        <Route component={SejaUmFranqueado} path="/seja-um-franqueado" />
        <Route component={LojaTradicional} path="/loja-tradicional" />
        <Route component={LojaShopping} path="/loja-shopping" />
        <Route component={LojaBasica} path="/loja-basica" />
        <Route component={Quiosque} path="/quiosque" />
        <Route component={ModelosExternos} path="/modelos-externos" />
        <Route component={Contato} path="/contato" />

        <Route component={Produtos} path="/produtos" exact />
        {/* <Route component={InformacoesNutricionais}   path="/produtos/informacoes-nutricionais"
        /> */}

        {/* <Route component={CardapioOnline} path="/cardapio-online/:franquia" exact/>
        <Route component={Produtos} path="/cardapio-online/:franquia/:categoria/produtos" /> */}
        <Route
          component={CardapioOnlineFull}
          path="/cardapio-online/:franquia"
        />

        <Route component={HomeFull} path="*" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
