import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MainSlider from '../../components/MainSlider';
import MainSliderMobile from '../../components/MainSliderMobile';

import api from '../../services/api';

import './styles.scss';

import desenhoCopo from '../../assets/img/onde-tem-desenho-copo.svg';
import milkshake from '../../assets/img/onde-tem-milkshake.png';
import arcoSejaUmFranqueado from '../../assets/img/seja-um-franqueado-arco.png';
import sejaUmFranqueado from '../../assets/img/seja-um-franqueado-title.png';
import lojaSejaUmFranqueado from '../../assets/img/seja-um-franqueado-arco-loja.png';
import lojaSejaUmFranqueadoMobile from '../../assets/img/seja-um-franqueado-arco-loja-mobile.png';
import logoChiquinho from '../../assets/img/logo-chq-250-250.png';
import casquinha from '../../assets/img/sobre-a-marca-casquinha.png';
import logoAntigo from '../../assets/img/sobre-a-marca-logo-antigo.png';

import dash1 from '../../assets/img/tracejados/1.svg';
import dash2 from '../../assets/img/tracejados/2.svg';
import dash3 from '../../assets/img/tracejados/3.svg';
import dash4 from '../../assets/img/tracejados/4.svg';
import dash5 from '../../assets/img/tracejados/5.svg';
import dash6 from '../../assets/img/tracejados/6.svg';
import dash7 from '../../assets/img/tracejados/7.svg';
import dash8 from '../../assets/img/tracejados/8.svg';
import dash9 from '../../assets/img/tracejados/9.svg';
import dash10 from '../../assets/img/tracejados/10.svg';
import dash11 from '../../assets/img/tracejados/11.svg';
import dash12 from '../../assets/img/tracejados/12.svg';
import dash13 from '../../assets/img/tracejados/13.svg';
import dash14 from '../../assets/img/tracejados/14.svg';
import dash15 from '../../assets/img/tracejados/15.svg';
import dash16 from '../../assets/img/tracejados/16.svg';

const HomeFull = () => {
  const [scrolled, setScrolled] = useState(false);
  const [width, setWidth] = useState(0);

  const [showDialogFranquias, setShowDialogFranquias] = useState(false);

  const [estado, setEstado] = useState({});
  const [cidade, setCidade] = useState({});
  const [listaEstados, setListaEstados] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);
  const [listaFranquias, setListaFranquias] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    function updateSize() {
      setWidth(window.innerWidth);
    }

    async function getEstadosContemFranquia() {
      await api.get('estado?key=f3a06f73b2d8dc7c3befe2c287981418').then(res => {
        const ufInitials = res.data.map(uf => uf);
        setListaEstados(ufInitials);
      });
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateSize);
    updateSize();
    getEstadosContemFranquia();
  }, []);

  useEffect(() => {
    async function getCidadesPorEstadoContemFranquias() {
      await api
        .get(`cidade/${estado.sigla}?key=cff6872cbdef2dcf71b161ff88965467`)
        .then(res => {
          let cidadeInitials = res.data.map(cidade => cidade);

          setListaCidades(cidadeInitials);
        });
    }

    getCidadesPorEstadoContemFranquias();
  }, [estado]);

  useEffect(() => {
    async function getFranquiasCidadeSelecionada() {
      await api
        .get(
          `franquia/${cidade.codigoIbge}?key=f49ab097bcb1168cf755ad28dd8349c7`,
        )
        .then(res => {
          setListaFranquias(res.data.map(franquia => franquia));
        })
        .catch(err => console.log('Erro: ' + err));
    }

    getFranquiasCidadeSelecionada();
  }, [cidade]);

  useEffect(() => {
    if (listaFranquias.length > 0) {
      setShowDialogFranquias(true);
    }
  }, [listaFranquias]);

  function handleClickLink() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <>
      <Header />

      <section id="main-slider">
        {width >= 768 ? <MainSlider /> : <MainSliderMobile />}
      </section>

      <section className="home-onde-tem">
        {width >= 768 ? (
          <div className="p-col-12 p-md-10 p-lg-8 arco-azul-content">
            <div className="p-grid">
              <div
                className="p-col-12 p-md-6 p-lg-6 p-d-flex"
                style={{ paddingBottom: '5rem' }}
              >
                <div className="main">
                  <div className="main-top">
                    <div className="group-copo-onde-tem p-d-flex">
                      <img
                        src={desenhoCopo}
                        alt="desenhoCopo"
                        height={130}
                        className="desenho-copo fade-in delay-0-5"
                      />
                      <img
                        src={dash1}
                        alt="dash1"
                        height={20}
                        className={`dash dash1 delay-0-6 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash2}
                        alt="dash2"
                        height={24}
                        className={`dash dash2 delay-0-7 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash3}
                        alt="dash3"
                        height={20}
                        className={`dash dash3 delay-0-8 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash4}
                        alt="dash4"
                        height={8}
                        className={`dash dash4 delay-0-9 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash5}
                        alt="dash5"
                        height={12}
                        className={`dash dash5 delay-1-0 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash6}
                        alt="dash6"
                        height={20}
                        className={`dash dash6 delay-1-1 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash7}
                        alt="dash7"
                        height={20}
                        className={`dash dash7 delay-1-2 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash8}
                        alt="dash8"
                        height={20}
                        className={`dash dash8 delay-1-3 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash9}
                        alt="dash9"
                        height={11}
                        className={`dash dash9 delay-1-4 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash10}
                        alt="dash10"
                        height={4}
                        className={`dash dash10 delay-1-5 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash11}
                        alt="dash11"
                        height={12}
                        className={`dash dash11 delay-1-6 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash12}
                        alt="dash12"
                        height={22}
                        className={`dash dash12 delay-1-7 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash13}
                        alt="dash13"
                        height={22}
                        className={`dash dash13 delay-1-8 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash14}
                        alt="dash14"
                        height={16}
                        className={`dash dash14 delay-1-9 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash15}
                        alt="dash15"
                        height={10}
                        className={`dash dash15 delay-2-0 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                      <img
                        src={dash16}
                        alt="dash16"
                        height={8}
                        className={`dash dash16 delay-2-1 ${
                          scrolled ? 'fade-in' : 'display-none'
                        }`}
                      />
                    </div>
                  </div>
                  <div className="main-bottom">
                    <h3>ONDE TEM?</h3>

                    <form>
                      <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                          <label htmlFor="phone">Estado e Cidade</label>
                          <div className="p-d-flex">
                            <Dropdown
                              id="estado"
                              value={estado}
                              options={listaEstados}
                              onChange={e => setEstado(e.value)}
                              optionLabel="sigla"
                              placeholder="Selecione seu estado"
                            />
                          </div>
                        </div>
                        <div className="p-field p-col-12">
                          <div className="p-d-flex">
                            <Dropdown
                              id="cidade"
                              disabled={
                                estado.sigla !== undefined ? false : true
                              }
                              value={cidade}
                              options={listaCidades}
                              onChange={e => setCidade(e.value)}
                              optionLabel="cidade"
                              placeholder="Selecione a cidade"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6 p-lg-6 p-d-flex content-milkshake">
                <img src={milkshake} alt="milkshake" className="milkshake" />
              </div>
            </div>
          </div>
        ) : (
          <div className="p-col arco-azul-content-mobile">
            <div className="main">
              <div className="p-grid">
                <div className="p-col-12 p-md-6 p-lg-6 p-d-flex content-milkshake">
                  <img src={milkshake} alt="milkshake" className="milkshake" />
                </div>
                <div className="p-col-12">
                  <div className="main-bottom">
                    <h3>ONDE TEM?</h3>

                    <form>
                      <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                          <label htmlFor="phone">Estado e Cidade</label>
                          <div className="p-d-flex">
                            <Dropdown
                              id="estado"
                              value={estado}
                              options={listaEstados}
                              onChange={e => setEstado(e.value)}
                              optionLabel="sigla"
                              placeholder="UF"
                            />
                          </div>
                        </div>
                        <div className="p-field p-col-12">
                          <div className="p-d-flex">
                            <Dropdown
                              id="cidade"
                              disabled={
                                estado.sigla !== undefined ? false : true
                              }
                              value={cidade}
                              options={listaCidades}
                              onChange={e => setCidade(e.value)}
                              optionLabel="cidade"
                              placeholder="Selecione a cidade"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className="home-seja-um-franqueado">
        <div className="p-grid">
          {width >= 768 ? (
            <>
              <div className="p-col-3 p-md-3 p-lg-4 col-content">
                <img
                  src={arcoSejaUmFranqueado}
                  alt="Arco"
                  className="arco"
                  data-aos="fade-right"
                />
              </div>
              <div className="p-col-3 p-md-5 p-lg-4 main">
                <img
                  src={sejaUmFranqueado}
                  alt="Seja um Franqueado"
                  className="seja-um-franqueado-title"
                />

                <b data-aos="fade-up-left">
                  Com mais de 750 unidades espalhadas por todo o país, a
                  Chiquinho se consolidou como a maior rede de sorveterias do
                  Brasil. Nossos franqueados confiam na estrutura da
                  franqueadora e expandem cada vez mais seus negócios.
                </b>

                <Link
                  to="/seja-um-franqueado"
                  data-aos="fade-up-left"
                  className="p-button-link"
                  onClick={e => handleClickLink()}
                >
                  Faça parte dessa história!
                </Link>
              </div>
              <div className="p-col-3 p-md-4 p-lg-4 col-content">
                <img
                  src={lojaSejaUmFranqueado}
                  alt="Loja"
                  className="loja"
                  data-aos="fade-right"
                />
              </div>
            </>
          ) : (
            <>
              <div className="p-col-12 main">
                <img
                  src={sejaUmFranqueado}
                  alt="Seja um Franqueado"
                  className="seja-um-franqueado-title"
                />

                <b>
                  Com mais de 760 unidades espalhadas por todo o país, a
                  Chiquinho se consolidou como a maior rede de sorveterias do
                  Brasil. Nossos franqueados confiam na estrutura da
                  franqueadora e expandem cada vez mais seus negócios.
                </b>

                <Link
                  to="/seja-um-franqueado"
                  className="p-button-link"
                  onClick={e => handleClickLink()}
                >
                  Faça parte dessa história!
                </Link>
              </div>
              <div className="p-col-12 content-loja">
                <img
                  src={arcoSejaUmFranqueado}
                  alt="Arco"
                  className="arco-mobile"
                />

                <img
                  src={lojaSejaUmFranqueadoMobile}
                  alt="Loja"
                  className="loja"
                />
              </div>
            </>
          )}
        </div>
      </section>

      <section className="home-sobre-a-marca">
        <div className="p-col-12 p-md-10 p-lg-8">
          <div className="p-grid">
            <div className="p-col-12 p-md-7 p-lg-7 p-d-flex p-jc-center">
              <div className="box-img">
                <img
                  src={casquinha}
                  alt="Casquinha"
                  className="casquinha"
                  data-aos="fade-up"
                />
                <img
                  src={logoAntigo}
                  alt="Logo Antigo"
                  className="logo-antigo"
                  data-aos={width >= 768 && 'zoom-in'}
                />
                <img
                  src={logoChiquinho}
                  alt="Logo Chiquinho"
                  className="logo-chiquinho"
                  data-aos={width >= 768 && 'fade-up-left'}
                />
              </div>
            </div>
            <div className="p-col-12 p-md-5 p-lg-5 p-d-flex p-ai-center">
              <div data-aos="fade-down">
                <h1>SOBRE A MARCA</h1>
                <span>
                  Tudo começou quando <b>Francisco</b>, o Chiquinho, presenteou
                  seu filho com uma pequena sorveteria no interior de Minas
                  Gerais. A primeira loja era pequena, mas o <b>sonho</b> de
                  levar felicidade em forma de sorvete era gigante. Em 2010 a
                  Chiquinho aderiu ao franchising e hoje, com mais de
                  <b> 750 lojas</b> espalhadas pelo Brasil, continua com a
                  proposta de levar <b>sorrisos</b> a cada colherada.
                </span>

                <Link to="/sobre-a-marca" onClick={e => handleClickLink()}>
                  Saiba mais {'>'}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <Dialog
        id="dialog-chq"
        visible={showDialogFranquias}
        header="Lojas perto de você"
        style={{ width: '80vw' }}
        blockScroll={true}
        onHide={() => setShowDialogFranquias(false)}
      >
        <form>
          <div className="p-fluid p-formgrid p-grid">
            {listaFranquias.map(frn => {
              return (
                <div className="p-col-12 p-md-4 p-lg-4 p-d-flex p-flex-column card">
                  <h1>{frn.nome}</h1>
                  <span>{frn.endereco}</span>
                  <span>{frn.complemento}</span>
                  {frn.horarioFuncionamento && (
                    <div className="p-d-flex p-flex-column">
                      <b>Horário de funcionamento:</b>
                      <span>{frn.horarioFuncionamento}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default HomeFull;
