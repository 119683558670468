import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const Quiosque = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="quiosque">
        <div className="p-d-flex p-jc-center header-quiosque">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">QUIOSQUE</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>PERFIL</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
              <li>Compacto e moderno, o quiosque proporciona aos clientes uma experiência agradável, em pontos estratégicos, fora de lojas convencionais;</li>
              <li>O formato de negócio foi desenvolvido para expandir e valorizar ainda mais a imagem da Chiquinho e de seus produtos, em locais com alto fluxo de pessoas como shoppings, galerias, hipermercados e aeroportos;</li>
              <li>Está disponível nas versões de 9 m² e 12 m².</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
              <li>Faturamento médio mensal: R$ 80 mil</li>
              <li>Lucro médio mensal entre 20% e 25%</li>
              <li>Prazo de retorno de 24 a 36 meses</li>
              <li>Investimento inicial R$ 344.000,00*</li>
              <li>Taxa de Franquia R$ 45.000,00</li>
              <li>Taxa de Royalties 5%</li>
              <li>Taxa de Publicidade 2%</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, impostos, frete etc. Valor do estoque está incluso no investimento inicial.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Quiosque;
