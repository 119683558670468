import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.scss';

import Diferenciais from '../../assets/img/seja-um-franqueado-diferenciais.png';
import Processo from '../../assets/img/seja-um-franqueado-processo.png';
import Anos40 from '../../assets/img/seja-um-franqueado-40-anos.png';
import icon1 from '../../assets/img/seja-um-franqueado-icon1.svg';
import icon2 from '../../assets/img/seja-um-franqueado-icon2.svg';
import icon3 from '../../assets/img/seja-um-franqueado-icon3.svg';
import icon4 from '../../assets/img/seja-um-franqueado-icon4.svg';
import icon5 from '../../assets/img/seja-um-franqueado-icon5.svg';
import icon6 from '../../assets/img/seja-um-franqueado-icon6.svg';
import icon7 from '../../assets/img/seja-um-franqueado-icon7.svg';
import icon8 from '../../assets/img/seja-um-franqueado-icon8.svg';
import icon9 from '../../assets/img/seja-um-franqueado-icon9.svg';
import icon10 from '../../assets/img/seja-um-franqueado-icon10.svg';
import icon11 from '../../assets/img/seja-um-franqueado-icon11.svg';
import icon12 from '../../assets/img/seja-um-franqueado-icon12.svg';
import icon13 from '../../assets/img/seja-um-franqueado-icon13.svg';


const Contato = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }

    updateSize();
  }, []);

  return (
    <>
      <Header />

      <section id="seja-um-franqueado">
        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-8">
            <h3 data-aos="fade-right">ENTRE EM CONTATO</h3>
            <p data-aos="fade-right">
              Sua opinião é muito importante!
            </p>
          </div>
        </div>



        

        

        <section className="form-franqueado" data-aos="fade-left">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-8">
              <div className="form-franqueado-content">
                <h3></h3>
 
                {/*<iframe title="iframe"
                  width="100%"
                  height="1800"
                  src="https://app.pipefy.com/public/form/ve4tKyU5?embedded=true"
                  frameborder="0"
  ></iframe>*/}


                <iframe title="iframe"
                  width="100%"
                  height="1800"
                  src="https://app.pipefy.com/public/form/ve4tKyU5?embedded=true"
                  frameborder="0"
  ></iframe>
                
              </div>
            </div>
          </div>
        </section>


      </section>

      <Footer />
    </>
  );
};

export default Contato;