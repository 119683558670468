import React, { useEffect, useState } from 'react';

import HeaderWeb from '../../components/Header/HeaderWeb';
import HeaderMobile from '../../components/Header/HeaderMobile';

const Header = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
  }, []);

  return <>{width >= 768 ? <HeaderWeb /> : <HeaderMobile />}</>;
};

export default Header;
