import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const LojaBasica = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="loja-basica">
        <div className="p-d-flex p-jc-center header-basica">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">LOJA BÁSICA</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>PERFIL</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
              <li>Atende as cidades com população menor do que 50 mil habitantes, exclusivamente;</li>
              <li>Ponto estratégico de consumo que aproxima as pessoas para viver novas experiências, num ambiente personalizado;</li>
              <li>Modelo de baixo custo operacional e gestão descomplicada, resultando numa melhor performance.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
              <li>Faturamento médio mensal: R$ 65 mil</li>
              <li>Lucro médio mensal entre 20% e 25%</li>
              <li>Prazo de retorno de 24 a 36 meses</li>
              <li>Investimento inicial R$ 380.000,00*</li>
              <li>Taxa de Franquia R$ 35.000,00</li>
              <li>Taxa de Royalties 5%</li>
              <li>Taxa de Publicidade 2%</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, impostos, frete etc. Valor do estoque está incluso no investimento inicial.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LojaBasica;
