
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import { FaAngleRight } from 'react-icons/fa';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.scss';

import Diferenciais from '../../assets/img/seja-um-franqueado-diferenciais.png';
import Anos40 from '../../assets/img/seja-um-franqueado-40-anos.png';
import icon1 from '../../assets/img/seja-um-franqueado-icon1.svg';
import icon2 from '../../assets/img/seja-um-franqueado-icon2.svg';
import icon3 from '../../assets/img/seja-um-franqueado-icon3.svg';
import icon4 from '../../assets/img/seja-um-franqueado-icon4.svg';
import icon5 from '../../assets/img/seja-um-franqueado-icon5.svg';
import icon6 from '../../assets/img/seja-um-franqueado-icon6.svg';
//import Processo from '../../assets/img/seja-um-franqueado-processo.png';
//import icon7 from '../../assets/img/seja-um-franqueado-icon7.svg';
//import icon8 from '../../assets/img/seja-um-franqueado-icon8.svg';
//import icon9 from '../../assets/img/seja-um-franqueado-icon9.svg';
//import icon10 from '../../assets/img/seja-um-franqueado-icon10.svg';
//import icon11 from '../../assets/img/seja-um-franqueado-icon11.svg';
//import icon12 from '../../assets/img/seja-um-franqueado-icon12.svg';
//import icon13 from '../../assets/img/seja-um-franqueado-icon13.svg';
import { Widget } from '@typeform/embed-react'

const SejaUmFranqueado = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }

    updateSize();
  }, []);

  return (
    <>
      <Header />

      <section id="seja-um-franqueado">
        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-8">
            <h3 data-aos="fade-right">SEJA UM FRANQUEADO</h3>
            <p data-aos="fade-right">
              Empreender em uma franquia da Chiquinho é uma forma segura e
              rentável de construir seu sonho. Com estrutura própria e know-how
              especializado em franchising, seu investimento possuirá bases
              sólidas e acompanhamento constante.
            </p>
          </div>
        </div>


        <section className="anos-40">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-8">
              <div className="p-grid">
                <div className="p-col-12 p-md-6 p-lg-6">
                  <img
                    src={Anos40}
                    alt="40 anos"
                    className="anos-40-img"
                    data-aos="fade-up-right"
                  />
                </div>
                <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                  <p data-aos="fade-up-left">
                    São mais de 40 anos de história que garantem a especialização no
                    segmento de sorvetes, segurança de investimento em todos os
                    modelos de negócio e retorno financeiro expressivo em pouco
                    tempo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="diferenciais-da-chiquinho">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-6">
              <img
                src={Diferenciais}
                alt="Diferenciais da Chiquinho"
                className="diferenciais-img"
              />
              <div className="p-grid">
                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="100"
                >
                  <img
                    src={icon1}
                    height={130}
                    width={130}
                    alt="Mais de 750 lojas"
                    className="anos-40-img"
                  />
                  <span>Mais de 750 Lojas</span>
                </div>

                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                >
                  <img
                    src={icon2}
                    height={130}
                    width={130}
                    alt="Presente em todos os estados brasileiros"
                    className="anos-40-img"
                  />
                  <span>Presente em todos os estados brasileiros</span>
                </div>

                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                >
                  <img
                    src={icon3}
                    height={130}
                    width={130}
                    alt="Desde 1980 no segmento de sorvetes"
                    className="anos-40-img"
                  />
                  <span>Desde 1980 no segmento de sorvetes</span>
                </div>

                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="300"
                >
                  <img
                    src={icon4}
                    height={130}
                    width={130}
                    alt="Mais de 32 milhões de clientes atendidos ao ano"
                    className="anos-40-img"
                  />
                  <span>Mais de 32 milhões de clientes atendidos ao ano</span>
                </div>

                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="400"
                >
                  <img
                    src={icon5}
                    height={130}
                    width={130}
                    alt="Mais de 53 milhões de sorvetes vendidos ao ano"
                    className="anos-40-img"
                  />
                  <span>Mais de 53 milhões de sorvetes vendidos ao ano</span>
                </div>

                <div
                  className="p-col-6 p-md-4 p-lg-4 box-icon"
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="500"
                >
                  <img
                    src={icon6}
                    height={130}
                    width={130}
                    alt="Influenciadores com alto engajamento nas mídias"
                    className="anos-40-img"
                  />
                  <span>Influenciadores com alto engajamento nas mídias</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="modelos-de-negocio">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-8">
              <h3>MODELOS DE NEGÓCIO</h3>

              <div className="modelo-loja">
                <div className="p-grid">
                  <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                    <div>
                      <h1>LOJA TRADICIONAL</h1>
                      <p>
                        Com estrutura completa, a loja tradicional atende ao
                        investidor que se identifica com grandes cidades e
                        centros comerciais.
                      </p>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-d-flex p-jc-center"
                    data-aos="zoom-in"
                  >
                    <Link
                      to="/loja-tradicional"
                      className="loja img-loja-tradicional"
                    />
                  </div>
                </div>
              </div>

              <div className="modelo-loja">
                <div className="p-grid reverse">
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-d-flex p-jc-center"
                    data-aos="zoom-in"
                  >
                    <Link
                      to="/loja-shopping"
                      className="loja img-loja-shopping"
                    />
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                    <div>
                      <h1>LOJA DE SHOPPING</h1>
                      <p>Elaborada para shoppings e galerias, a loja de shopping tem layout preparado para as exigências das administrações dos centros comerciais.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modelo-loja">
                <div className="p-grid">
                  <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                    <div>
                      <h1>LOJA BÁSICA</h1>
                      <p>A loja básica é ideal para o empreendedor que se identifica com as cidades interioranas com população inferior a 50 mil habitantes.</p>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-d-flex p-jc-center"
                    data-aos="zoom-in"
                  >
                    <Link to="/loja-basica" className="loja img-loja-basica" />
                  </div>
                </div>
              </div>

              <div className="modelo-loja">
                <div className="p-grid reverse">
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-d-flex p-jc-center"
                    data-aos="zoom-in"
                  >
                    <Link to="/quiosque" className="loja img-loja-quiosque" />
                  </div>
                  <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                    <div>
                      <h1>QUIOSQUE</h1>
                      <p>O quiosque tem formato reduzido e pode ser construído em galerias e shoppings, entre outros locais próprios para o varejo.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modelo-loja">
                <div className="p-grid">
                  <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center">
                    <div>
                      <h1>MODELOS EXTERNOS</h1>
                      <p>
                        Os modelos externos possuem um design mais prático e otimizado, levando em consideração os pontos estratégicos para a implantação desta modalidade.
                      </p>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-6 p-lg-6 p-d-flex p-jc-center"
                    data-aos="zoom-in"
                  >
                    <Link to="/modelos-externos" className="loja img-modelos-externos" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/*<section className="processo-franqueado">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-8">
              <img
                src={Processo}
                alt="Processo para ser um franqueado"
                className="processo-img"
              />

              {width >= 768 ? (
                <div className="p-d-flex p-flex-wrap p-ai-center p-jc-center">
                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="100"
                  >
                    <img
                      src={icon7}
                      height={110}
                      width={110}
                      alt="Cadastro e Análise"
                      className="anos-40-img"
                    />
                    <span>Cadastro e Análise</span>
                  </div>

                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="150"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="200"
                  >
                    <img
                      src={icon8}
                      height={110}
                      width={110}
                      alt="Reunião de apresentação"
                      className="anos-40-img"
                    />
                    <span>Reunião de apresentação</span>
                  </div>

                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="250"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="300"
                  >
                    <img
                      src={icon9}
                      height={110}
                      width={110}
                      alt="Prospecção e avaliação do ponto"
                      className="anos-40-img"
                    />
                    <span>Prospecção e avaliação do ponto</span>
                  </div>

                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="350"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="400"
                  >
                    <img
                      src={icon10}
                      height={110}
                      width={110}
                      alt="Documentação"
                      className="anos-40-img"
                    />
                    <span>Documentação</span>
                  </div>
                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="450"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="500"
                  >
                    <img
                      src={icon11}
                      height={110}
                      width={110}
                      alt="Análise do comitê e validação"
                      className="anos-40-img"
                    />
                    <span>Análise do comitê e validação</span>
                  </div>

                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="550"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="600"
                  >
                    <img
                      src={icon12}
                      height={110}
                      width={110}
                      alt="Treinamento"
                      className="anos-40-img"
                    />
                    <span>Treinamento</span>
                  </div>

                  <div
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="650"
                  >
                    <FaAngleRight size={70} color="#fff" />
                  </div>

                  <div
                    className="p-d-flex p-flex-column p-ai-center"
                    style={{ width: 170, marginBottom: 10 }}
                    data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="700"
                  >
                    <img
                      src={icon13}
                      height={110}
                      width={110}
                      alt="Inauguração"
                      className="anos-40-img"
                    />
                    <span>Inauguração</span>
                  </div>
                </div>
              ) : (
                <div className="p-d-flex">
                  <div className="p-grid">
                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="100"
                    >
                      <img
                        src={icon7}
                        height={110}
                        width={110}
                        alt="Cadastro e Análise"
                        className="anos-40-img"
                      />
                      <span>Cadastro e Análise</span>
                    </div>

                    <div className="p-col-4 p-d-flex p-ai-center p-jc-center">
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="150"
                      >
                        <FaAngleRight size={70} color="#fff" />
                      </div>
                    </div>

                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="200"
                    >
                      <img
                        src={icon8}
                        height={110}
                        width={110}
                        alt="Reunião de apresentação"
                        className="anos-40-img"
                      />
                      <span>Reunião de apresentação</span>
                    </div>

                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="300"
                    >
                      <img
                        src={icon9}
                        height={110}
                        width={110}
                        alt="Prospecção e avaliação do ponto"
                        className="anos-40-img"
                      />
                      <span>Prospecção e avaliação do ponto</span>
                    </div>

                    <div className="p-col-4 p-d-flex p-ai-center p-jc-center">
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="350"
                      >
                        <FaAngleRight size={70} color="#fff" />
                      </div>
                    </div>

                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="400"
                    >
                      <img
                        src={icon10}
                        height={110}
                        width={110}
                        alt="Documentação"
                        className="anos-40-img"
                      />
                      <span>Documentação</span>
                    </div>

                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="500"
                    >
                      <img
                        src={icon11}
                        height={110}
                        width={110}
                        alt="Análise do comitê e validação"
                        className="anos-40-img"
                      />
                      <span>Análise do comitê e validação</span>
                    </div>

                    <div className="p-col-4 p-d-flex p-ai-center p-jc-center">
                      <div
                        data-aos="fade-zoom-in"
                        data-aos-easing="ease-in-back"
                        data-aos-delay="550"
                      >
                        <FaAngleRight size={70} color="#fff" />
                      </div>
                    </div>

                    <div
                      className="p-col-4 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="600"
                    >
                      <img
                        src={icon12}
                        height={110}
                        width={110}
                        alt="Treinamento"
                        className="anos-40-img"
                      />
                      <span>Treinamento</span>
                    </div>

                    <div
                      className="p-col-12 p-d-flex p-flex-column p-ai-center"
                      data-aos="fade-zoom-in"
                      data-aos-easing="ease-in-back"
                      data-aos-delay="700"
                    >
                      <img
                        src={icon13}
                        height={110}
                        width={110}
                        alt="Inauguração"
                        className="anos-40-img"
                      />
                      <span>Inauguração</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
              </section>*/}

        <section className="form-franqueado" data-aos="fade-left">
          <div className="p-d-flex p-jc-center">
            <div className="p-col-12 p-md-10 p-lg-8">
              <div className="form-franqueado-content">
                <h3>Quer ser parte dessa história?</h3>


{/*}
<iframe title="iframe"
	width="100%"
	height="1800"
	src="https://app.pipefy.com/public/form/ve4tKyU5?embedded=true"
	frameborder="0"
></iframe> 
*/}



<Widget id="YaNdpAGs" className='typeform-chiquinho'/>

              </div>
            </div>
          </div>
        </section>


      </section>

      <Footer />
    </>
  );
};

export default SejaUmFranqueado;