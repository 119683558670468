import React, { useEffect, useState } from 'react';
import Aos from 'aos';

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'aos/dist/aos.css';

import { Button } from 'primereact/button';

import Routes from './routes';

function App() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  window.addEventListener('scroll', checkScrollTop);

  useEffect(() => {
    Aos.init({ duration: 1000 });

    document.addEventListener('contextmenu', event => event.preventDefault());
  }, []);

  return (
    <>
      <Button
        id="button-return-top"
        icon="pi pi-angle-double-up"
        className="p-button-rounded"
        onClick={scrollTop}
        style={{ display: showScroll ? 'flex' : 'none' }}
      />

      <div className="App">
        <Routes />
      </div>
    </>
  );
}

export default App;
