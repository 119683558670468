import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

import logoChq from '../../../assets/img/logo-chq-250-250.png';

const HeaderWeb = () => {
  function handleClickMenu(menu) {
    const pageHeight = window.innerHeight;
    const menuId = menu.id;

    if (menuId && menuId === 'onde-tem') {
      window.scrollTo({ top: pageHeight + 30, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  return (
    <>
      <header>
        <div className="p-d-flex p-ai-center">
          <div className="p-md-1 p-lg-2 aside"></div>
          <div className="p-md-10 p-lg-8 main">
            <div className="content-logo">
              <div className="before-logo"></div>
              <Link to="/">
                <img
                  src={logoChq}
                  alt="Logo Chiquinho"
                  width={90}
                  className="logo-chiquinho"
                />
              </Link>
              <div className="after-logo"></div>
            </div>
            <nav>
              <ul>
                <li>
                  <Link to="/" onClick={e => handleClickMenu(e.target)}>
                    <span className="menu-item-title">HOME</span>
                  </Link>
                </li>
                <li>
                  <Link to="/produtos" onClick={e => handleClickMenu(e.target)}>
                    <span className="menu-item-title">PRODUTOS</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={e => handleClickMenu(e.target)}>
                    <span id="onde-tem" className="menu-item-title">
                      ONDE TEM?
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/seja-um-franqueado"
                    onClick={e => handleClickMenu(e.target)}
                  >
                    <span className="menu-item-title">SEJA UM FRANQUEADO</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/sobre-a-marca"
                    onClick={e => handleClickMenu(e.target)}
                  >
                    <span className="menu-item-title">SOBRE A MARCA</span>
                  </Link>
                </li>
                <li className="menu-destaque">
                  <a
                    href="https://ifoodbr.onelink.me/F4X4/chiquinhosorvetes"
                    target='_blank'
                  >
                    <span className="menu-item-title">PEÇA AQUI</span>
                  </a>
  </li>              
              </ul>
            </nav>
          </div>
          <div className="p-md-1 p-lg-2 aside"></div>
        </div>
      </header>
    </>
  );
};

export default HeaderWeb;
