import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const LojaTradicional = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="loja-tradicional">
        <div className="p-d-flex p-jc-center header-tradicional">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">LOJA TRADICIONAL</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>PERFIL</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
              <li>Modelo de negócio exclusivamente para as cidades com mais de 50 mil habitantes;</li>
              <li>Implantação preferencialmente em pontos com alto fluxo de pessoas que geram consumo;</li>
              <li>Imóvel com mais de 50 m², prezando pelo bem-estar do cliente, proporcionando uma experiência agradável, tornando esse espaço a área de lazer da população;</li>
              <li>Modelo com alto potencial de faturamento, melhor demanda de mercado que contempla ambiente ideal para o sucesso do negócio.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
              <li>Faturamento médio mensal: R$ 95 mil</li>
              <li>Lucro médio mensal entre 17% e 22%</li>
              <li>Prazo de retorno de 24 a 36 meses</li>
              <li>Investimento inicial R$ 400.000,00*</li>
              <li>Taxa de Franquia R$ 60.000,00</li>
              <li>Taxa de Royalties 5%</li>
              <li>Taxa de Publicidade 2%</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, impostos, frete etc. Valor do estoque está incluso no investimento inicial.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LojaTradicional;
