import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

import logoChq from '../../assets/img/logo-chq-250-250.png';
import contact from '../../assets/img/footer-contact.png';
import email from '../../assets/img/footer-email.png';
import facebook from '../../assets/img/footer-facebook-icon.svg';
import instagram from '../../assets/img/footer-instagram-icon.svg';
import twitter from '../../assets/img/footer-twitter-icon.svg';
import youtube from '../../assets/img/footer-youtube-icon.svg';

const Footer = () => {
  return (
    <footer className="p-d-flex p-jc-center">
      <div className="p-col-12 p-md-10 p-lg-8">
        <div className="p-grid p-d-flex footer-content">
          <div className="p-col-12 p-md-6 p-lg-6 p-d-flex p-ai-center p-jc-sm-center">
            <div className="p-d-flex p-ai-center ">
              <img
                src={logoChq}
                alt="Logo Chiquinho"
                width={130}
                height={130}
                className="logo-chiquinho"
              />

              <div className="p-d-flex p-flex-column p-ml-4 ">
                <h1 className="p-mt-2">FALE COM A GENTE</h1>
                <span>Se quiser bater um papo, ligue</span>
                <div className="p-d-flex p-ai-center">
                  <img
                    src={contact}
                    alt="Icone Tel"
                    width={20}
                    className="p-mr-2 "
                  />
                  <a href="tel:+55-017-3211-8200" className="tel">
                    +55 17 3211-8200
                  </a>
                </div>

                <div className="p-d-flex p-ai-center p-mt-2">
                  <img
                    src={email}
                    alt="Icone Tel"
                    width={20}
                    className="p-mr-2 "
                  />
                  <a href="https://app.pipefy.com/public/form/J8xQfQSW" target="_blank" className="email">
                    Entre em contato
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="p-col-6 p-md-3 p-lg-3 p-d-flex p-ai-center p-jc-sm-center centralizado">
            <div className="p-d-flex p-flex-column">
              <h3>MENU INSTITUCIONAL</h3>
              <Link to="/">Home</Link>
              {/*<Link to="/cardapio">Cardápio</Link>*/}
              <Link to="/">Onde tem?</Link>
              <Link to="/seja-um-franqueado">Seja um franqueado</Link>
              <Link to="/sobre-a-marca">Sobre a marca</Link>
              <a href="https://ifoodbr.onelink.me/F4X4/chiquinhosorvetes" target='_blank'>Peça aqui</a> 
              {/* <Link to="/">Tabela Nutricional</Link> */}
            </div>
          </div>

          <div className="p-col-6 p-md-3 p-lg-3 p-d-flex p-ai-center p-jc-sm-center centralizado">
            <div className="p-d-flex p-flex-column">
              <div className="p-d-flex p-ai-center p-mb-1">
                <img src={facebook} alt="Facebook" width={25} />
                <a
                  href="https://www.facebook.com/ChiquinhoSorvetes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /chiquinhosorvetes
                </a>
              </div>
              <div className="p-d-flex p-ai-center p-mb-1">
                <img src={instagram} alt="Instagram" width={25} />
                <a
                  href="https://www.instagram.com/chiquinhosorvetes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /chiquinhosorvetes
                </a>
              </div>
              <div className="p-d-flex p-ai-center p-mb-1">
                <img src={twitter} alt="Twitter" width={25} />
                <a
                  href="https://www.twitter.com/chiquinho"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /chiquinho
                </a>
              </div>
              <div className="p-d-flex p-ai-center">
                <img src={youtube} alt="YouTube" width={25} />
                <a
                  href="https://www.youtube.com/chiquinhosorvetes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /chiquinhosorvetes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
