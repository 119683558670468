import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.css';

import primeiraChq from '../../assets/img/sobre-a-marca-primeira-chq.png';
import isaias from '../../assets/img/sobre-a-marca-isaias.png';
import detalhe from '../../assets/img/sobre-a-marca-detalhe.svg';
import shakemix1 from '../../assets/img/sobre-a-marca-shakemix1.png';
import shakemix2 from '../../assets/img/sobre-a-marca-shakemix2.png';
import lojas from '../../assets/img/sobre-a-marca-lojas.png';

const SobreAMarca = () => {
  return (
    <>
      <Header />

      <section className="sobre-a-marca p-flex-column">
        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-6">
            <p data-aos="fade-right">
              A <b>Chiquinho Sorvetes</b> nasceu em 1980, quando Francisco, o
              Chiquinho, inaugurou uma pequena sorveteria na cidade de Frutal,
              Minas Gerais. A decisão de modificar o empreendimento e adaptar
              para um modelo diferenciado partiu de seu filho, Isaias.
            </p>
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-8">
            <img
              src={primeiraChq}
              alt="Foto da 1° Chiquinho"
              className="primeira-chq"
              data-aos="fade-left"
            />
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-6">
            <p data-aos="fade-down">
              Em 1984, Isaias assumiu o comando da sorveteria e expandiu o sonho
              de família. Muitos familiares compartilharam o negócio e deixaram
              a Chiquinho no gosto do público em diversas cidades brasileiras.
            </p>
            <p data-aos="fade-down">
              Nos anos 2010, com o crescimento rápido e desenvolvimento
              promissor, o empresário sentiu a necessidade de inovar e
              padronizar as lojas. Foi assim que a marca entrou no segmento de
              franchising.
            </p>
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="p-grid">
              <div className="p-col-12 p-md-6 p-lg-6">
                <img
                  src={isaias}
                  alt="Foto do Isaias"
                  className="foto-isaias"
                  data-aos="fade-right"
                />
              </div>
              <div className="p-col-12 p-md-6 p-lg-6 depoimento-isaias">
                <div>
                  <p data-aos="fade-left">
                    Nosso grande diferencial está no padrão de excelência dos
                    produtos. Estamos sempre em busca de novas opções para
                    oferecer aos clientes uma grande variedade de sabores e
                    combinações”, ressalta Isaias.
                  </p>
                  <img src={detalhe} alt="Detalhe" data-aos="fade-left" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-8">
            <div className="p-grid">
              <div className="p-col-4 p-md-4 p-lg-4 box-shakemix">
                <img
                  src={shakemix1}
                  alt="Shakemix"
                  data-aos="zoom-in-up"
                  className="shakemix"
                />
              </div>
              <div className="p-col-8 p-md-4 p-lg-4 p-d-flex p-ai-center">
                <p data-aos="fade-right">
                  A Chiquinho Sorvetes sempre apresenta novidades em produtos e
                  promoções. Um dos segredos do sucesso da marca está na receita
                  exclusiva da base do sorvete, o que garante sabor único e
                  inconfundível dos produtos.
                </p>
              </div>
              <div className="p-col-0 p-md-4 p-lg-4 box-shakemix">
                <img
                  src={shakemix2}
                  alt="Shakemix"
                  data-aos="zoom-out-up"
                  className="shakemix shakemix2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-6">
            <p data-aos="fade-down">Hoje, a rede tem mais de 750 lojas. Em 2023, alcançamos uma importante meta de expansão: chegar a todos os estados do país. De forma planejada, conseguimos atingir essa marca mantendo o padrão de excelência de atendimento e qualidade dos produtos, o que sempre caracterizou o negócio.</p>
          </div>
        </div>

        <div className="p-d-flex p-jc-center">
          <div className="p-col-12 p-md-10 p-lg-6">
            <img
              src={lojas}
              alt="Shakemix"
              data-aos="zoom-out-up"
              className="lojas"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SobreAMarca;
