import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

// import slideSeuSorvete from '../../assets/img/slide10mobile.jpg';
// import slideSeuSorvete from '../../assets/img/slide11mobile.jpg';
// import SlideSejaUmFranqueado from '../../assets/img/slide2-mobile.png';
// import Slide14 from '../../assets/img/slide14mobile.jpg'
// import Slide15 from '../../assets/img/slide15mobile.png'
//import Slide16 from '../../assets/img/slide16mobile.png'
//import Slide17 from '../../assets/img/slide17mobile.png';
//import BannerOreoMobile from '../../assets/img/banner-mobile-site-oreo.jpg';
//import BannerNovosMilkShakesMobile from '../../assets/img/2024/02/banner-site-milk-shakes-01-02-2024-mobile.jpg';
//import BannerEDMobile from '../../assets/img/2024/04/banner-site-mobile_edicao-limitada.jpg';
import BannerTurmaDoChiquinhoMobile from '../../assets/img/2024/09/banner-site-mobile-mes-das-criancas.jpg';

import './styles.css';

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      arrows: false,
    };

    return (
      <Slider {...settings}>
       
        <div>
          <a href='https://www.youtube.com/@turmadochiquinho' target='_blank'><img src={BannerTurmaDoChiquinhoMobile} alt="Mês das Crianças com a Turma do Chiquinho" /></a>
        </div>
    
        {/* <img src={BannerTurmaDoChiquinhoMobile} alt="Mês das Crianças com a Turma do Chiquinho" /> */}
          

       
      </Slider>
    );
  }
}
